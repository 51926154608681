import Utils from '@/modules/casino/utils/Utils';
import { initiateBridgerApplePayDeposit, updateBridgerApplePayDeposit } from '@/modules/casino/store/actions/deposit';
import { BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY, PaymentProvider } from '@/constants/paymentProvider';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';

const APPLE_PAY_PLACEHOLDER = 'bridger-apple-pay-placeholder';

class BridgerApplePay {
  constructor(data: any) {
    this.init(data);
  }

  private amount: any;
  private bonusId: any;
  private triggerSuccess: any;
  private triggerError: any;
  private dispatch: any;
  private cashierKey: any;
  private cashierToken: any;

  startSession() {
    if (!this.isApplePayAvailable()) return;
    if (this.amount > 0) {
      if (!(this.cashierKey && this.cashierToken)) {
        this.dispatch(
          initiateBridgerApplePayDeposit({
            amount: this.amount * 100,
            bonusId: this.bonusId || null,
          }),
        );
      }
    }
  }

  updateSession() {
    if (!this.isApplePayAvailable()) return;
    if (this.amount > 0) {
      this.dispatch(
        updateBridgerApplePayDeposit({
          amount: this.amount * 100,
          bonusId: this.bonusId || null,
        }),
      );
    }
  }

  injectAppleScript(data: any): void {
    this.destroy();
    Utils.injectScript(
      'https://ng-checkout.bridgerpay.com/v2/launcher',
      'bridger-apple-pay',
      () => {
        const { cashierKey, cashierToken } = data;
        this.cashierKey = cashierKey;
        this.cashierToken = cashierToken;

        //listen for message
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-result`,
          (e: any) => {
            const result = e.detail;
            if (result.status === 'approved') {
              this.triggerSuccess();
            } else {
              this.triggerError();
            }
            ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-cancelled`,
          () => {
            this.triggerError();
            ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:no-wallet-provider-found`,
          () => {
            this.triggerError();
            ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-provider-cannot-be-used`,
          () => {
            this.triggerError();
            ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-shipping-contact-update`,
          (e: any) => {
            if (e.error || e.detail?.error) {
              this.triggerError();
              ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
            }
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-button-is-ready`,
          () => {
            setTimeout(() => {
              this.sendAmountToApplePay(data.amount);
            }, 200);
          },
          false,
        );
      },
      {
        data: {
          'cashier-key': data.cashierKey,
          'cashier-token': data.cashierToken,
          'button-mode': 'wallet',
        },
        container: APPLE_PAY_PLACEHOLDER,
      },
    );
  }

  destroy(): void {
    // remove apple pay script and apple pay button
    const applePayButton = document.getElementById(APPLE_PAY_PLACEHOLDER);
    if (applePayButton) {
      applePayButton.innerHTML = '';
    }
  }

  init(data: any): void {
    this.triggerSuccess = data.triggerSuccess;
    this.triggerError = data.triggerError;
    this.dispatch = data.dispatch;
    this.cashierKey = data.cashierKey;
    this.cashierToken = data.cashierToken;
  }

  setAmount(amount: number): void {
    this.amount = amount;
    this.sendAmountToApplePay();
  }

  sendAmountToApplePay(amount: number = 0): void {
    let newAmount = amount;
    if (!amount) {
      newAmount = this.amount;
    }

    if (newAmount) {
      console.log('event dispatched', newAmount);
      window.dispatchEvent(
        new CustomEvent(`[bp][checkout:${this.cashierKey}]:wallet-update-amount`, {
          detail: {
            newAmount: newAmount,
          },
        }),
      );
    }
  }

  setBonusId(bonusId: number | null): void {
    this.bonusId = bonusId;
  }

  setPaymentDetails(data: any): void {
    const oldBonus = this.bonusId ?? null;
    const newBonus = data.bonusId ?? null;
    if (this.amount !== data.amount || oldBonus !== newBonus) {
      this.setAmount(data.amount);
      this.setBonusId(data.bonusId);
      this.updateSession();
    }
  }

  isApplePayAvailable() {
    if (
      //@ts-ignore
      window.ApplePaySession &&
      window.config.depositSettings?.providers?.[PaymentProvider.bridger].isApplePayActive === '1'
    ) {
      //@ts-ignore
      if (window.ApplePaySession.canMakePayments()) {
        return true;
      }
    }
    return false;
  }

  onApplePayButtonClicked = () => {};

  // return (<div className="apple-pay-button apple-pay-button-white-with-line add-money" onClick={onApplePayButtonClicked}></div>);
}

export default BridgerApplePay;
